import { defineStore } from 'pinia'

export const useSubprojectStore = defineStore('subproject', {
    state: () => ({
        domainInfo: null,
    }),
    actions: {
        setDomainInfo(info) {
            this.domainInfo = info;
        },
    },
})
