<template>
  <RouterView />
</template>

<script>
import { useSiteSettings } from '@/stores/siteSettings'

export default {
  async created() {
    const siteSettings = useSiteSettings()
    
    // Get current domain
    const domain = window.location.hostname
    
    // Medical system user defaults
    const user = {
      role: 'administrator', // Options: doctor, nurse, administrator, etc.
      department: 'Administration',
      licenseNumber: null, // Required for medical staff
      specialization: null, // Required for medical staff
      organization: {
        name: 'Default Hospital',
        facilityType: 'General Hospital', // Hospital type identification
        accreditationNumber: null, // Hospital accreditation
        departments: [], // List of medical departments
        emergencyContact: null // Emergency contact information
      }
    }
    
    // Initialize medical facility settings
    await siteSettings.initializeSiteSettings({ 
      domain, 
      user,
      isHealthcareFacility: true // Flag for medical-specific features
    })
  }
}
</script>