import { useAuthStore } from '@/stores/auth';
import { useValidationStore } from '@/stores/validation';
import { useRouter } from 'vue-router';
import axios from 'axios';

const apiUrl = import.meta.env.DEV
    ? 'http://hms-core.local/api/'
    : `https://web.codifyhealthcare.com/api/`;

const apiClient = axios.create({
  baseURL: apiUrl,
});

const setupInterceptors = (authStore, validationStore) => {
  const router = useRouter();
  apiClient.interceptors.request.use(
      (request) => {
        console.log("REQUEST STARTED")
        return request;
      },
      (error) => {
        console.log("REQUEST FINISHED ERROR")
        return Promise.reject(error);
      }
  );
  apiClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response?.status === 422) {
          validationStore.setErrors(error.response.data.errors)
        }

        if (error.response?.status === 403) {
          authStore.logout();
          return router.push("/login");
        }

        if (error.response?.status === 423) {
            console.log("SHOW NOTIFICATION")

        }

        if (error.response?.status === 401) {
          authStore.logout();
          return router.push("/login");
        }

        if (error.response?.status === 500) {
          console.log("SHOW NOTIFICATION")
        }

        if (error.response?.status === 404) {
          console.log("Redirect to page 404")
        }

        return Promise.reject(error);
      }
  );
};

export const initializeApi = (authStore, validationStore) => {
  setupInterceptors(authStore, validationStore);
};





export default ()=>({
  methods: {
    serialize(obj, parentKey = "", formData = new FormData()) {
      Object.entries(obj).forEach(([key, value]) => {
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        if (value instanceof File || value instanceof Blob) {
          formData.append(newKey, value, value.name);
        } else if (typeof value === "boolean") {
          formData.append(newKey, value ? "1" : "0");
        } else if (Array.isArray(value)) {
          value.forEach((item, index) => {
            this.serialize(item, `${newKey}[${index}]`, formData);
          });
        } else if (typeof value === "object" && value !== null) {
          this.serialize(value, newKey, formData);
        } else if (value !== null && value !== undefined) {
          formData.append(newKey, value);
        }
      });

      return formData;
    },
    async post(url, formData, callBackFn = null, files = false) {
      this.setHeader(files);
      return apiClient
          .post(url, this.serialize(formData))
          .then(({ data }) => callBackFn ? callBackFn(data) : data.data)
          .catch(() => console.log("Caught ERR on POST request"));
    },
    async get(url, callBackFn = null) {
      this.setHeader();
      return apiClient
          .get(url)
          .then(({ data }) => callBackFn ? callBackFn(data) : data.data)
          .catch(() => console.log("Caught ERR on GET request"));
    },
    async delete(url, callBackFn = null) {
      this.setHeader();
      return apiClient
          .delete(url)
          .then(({ data }) => callBackFn ? callBackFn(data) : data.data)
          .catch(() => console.log("Caught ERR on DELETE request"));
    },
    async put(url, formData, callBackFn = null, files = false) {
      this.setHeader(files);
      return apiClient
          .post(`${url}?_method=PUT`, this.serialize(formData))
          .then(({ data }) => callBackFn ? callBackFn(data) : data.data)
          .catch(() => console.log("Caught ERR on PUT request"));
    },
    async patch(url, formData, callBackFn = null) {
      this.setHeader();
      return apiClient
          .post(`${url}?_method=PATCH`, formData)
          .then(({ data }) => callBackFn ? callBackFn(data) : data.data)
          .catch(() => console.log("Caught ERR on PATCH request"));
    },
    setHeader(files = false) {
      const authStore = useAuthStore();
      const validationStore = useValidationStore();

      validationStore.resetErrors();
      if(authStore.token) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${authStore.token}`;
      }
      apiClient.defaults.headers.common['X-Domain'] = window.location.hostname;
      if(files){
        apiClient.defaults.headers.common['Content-Type'] = "multipart/form-data";
      }else{
        apiClient.defaults.headers.common['Content-Type'] = "application/json";
      }
    },
  },
});
