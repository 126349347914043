import { defineStore } from 'pinia';

export const useValidationStore = defineStore('validation', {
  state: () => ({
    errors: [],
    errorFields: [],
  }),

  actions: {
    resetErrors() {
      this.errors = [];
      this.errorFields = [];
    },
    resetError(fieldName){
      this.errorFields = this.errorFields.filter(fName=>fName!==fieldName)
      for (let fName in this.errors) {
        if (fName === fieldName) {
          delete this.errors[fieldName];
        }
      }
    },
    setErrors(errors) {
      this.errors = errors;
      this.errorFields = Object.keys(errors)
    },
  },
}); 