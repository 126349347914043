import { defineStore } from 'pinia';
import router from '@/router';

export const useAuthStore = defineStore('auth', {
  // ✅ Enable persistence
  persist: {
    key: 'auth',
    storage: localStorage,
  },
  state: () => ({
    user: null,
    token: null,
    isAuthenticated: false
  }),

  actions: {
    setUser(user) {
      this.user = user;

    },

    setToken(token) {
      this.token = token;
      localStorage.setItem('token', token);
      this.isAuthenticated = true;
    },

    async loginWithQR(token) {
      try {
        const response = await fetch('/api/auth/qr/authenticate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token })
        });

        if (!response.ok) {
          throw new Error('QR authentication failed');
        }

        const data = await response.json();
        this.setUser(data.user);
        this.setToken(data.token);
        
        // Redirect to dashboard
        router.push('/dashboard');
      } catch (error) {
        console.error('QR login error:', error);
        throw error;
      }
    },

    logout() {
      this.user = null;
      this.token = null;
      this.isAuthenticated = false;
      localStorage.removeItem('token');
      router.push('/login');
    }
  }
}); 