import { defineStore } from 'pinia'
import { resolveBasicLogo, getDomainParts } from '@/util/domainUtils'
import systemMetadata from '@/data/config/systemMetadata.json'

export const useSiteSettings = defineStore('siteSettings', {
  state: () => ({
    siteName: 'Default Site Name',
    siteDescription: 'yo',
    siteTheme: null,
    logo: null,
    primaryColor: '#007D88', // Default primary color
    systemConfig: null
  }),
  
  getters: {
    getSiteName: (state) => state.siteName,
    getLogo: (state) => state.logo,
    getPrimaryColor: (state) => state.primaryColor,
    getSystemConfig: (state) => state.systemConfig,
    getSiteDescription: (state) => state.siteDescription 
  },
  
  actions: {
    async initializeSiteSettings({ domain }) {
      // Extract domain parts
      const { country, system } = getDomainParts(domain);
      
      // Find matching health system from metadata
      const healthSystem = systemMetadata.health_systems.find(sys => 
        sys.id === system?.toUpperCase() || 
        sys.country.toLowerCase() === country?.toLowerCase()
      );

      if (healthSystem) {
        this.systemConfig = healthSystem;
        this.siteName = healthSystem.official_name;
        this.siteDescription = healthSystem.description;
        
        // Set logo based on the system's website
        this.logo = healthSystem.website_dev || healthSystem.website_official;

        // Set primary color based on system/country
        switch(healthSystem.id) {
          case 'NHS_UK':
            this.primaryColor = '#005EB8'; // NHS Blue
            break;
          case 'MEDICARE_AU':
            this.primaryColor = '#00A9E0'; // Medicare Australia Blue
            break;
          case 'MEDICARE_US':
            this.primaryColor = '#004890'; // Medicare US Blue
            break;
          case 'GKV_DE':
            this.primaryColor = '#009EE3'; // German Health Insurance Blue
            break;
          default:
            this.primaryColor = '#007D88'; // Default color
        }
      } else {
        // Fallback to defaults if no matching system found
        this.siteName = 'AI Governance for the Public Good: Real-World Solutions Across Education, Healthcare, & More';
        this.siteDescription = `The Hardison Co. AI-powered governance framework goes beyond theory—offering real, operational platforms like pub.school (global education), dietmanager.com (nutrition), and gov-ai.co (institutional oversight). By integrating ethical principles, transparent data practices, and community-driven collaboration, these systems aim to transform healthcare, finance, and government services worldwide. Learn how responsible AI can cure diseases, advance education, and tackle global challenges while protecting human rights.`;
        this.logo = resolveBasicLogo(domain);
        this.primaryColor = '#007D88';
      }

      // Set the document title
      document.title = this.siteName;

      // Update CSS custom properties
      document.documentElement.style.setProperty('--bs-primary', this.primaryColor);
      document.documentElement.style.setProperty('--bs-link-color', this.primaryColor);
      document.documentElement.style.setProperty('--bs-link-hover-color', this.adjustColor(this.primaryColor, 20));
    },

    adjustColor(color, percent) {
      const num = parseInt(color.replace('#', ''), 16);
      const amt = Math.round(2.55 * percent);
      const R = (num >> 16) + amt;
      const G = (num >> 8 & 0x00FF) + amt;
      const B = (num & 0x0000FF) + amt;
      return '#' + (
        0x1000000 +
        (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 +
        (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 +
        (B < 255 ? B < 1 ? 0 : B : 255)
      ).toString(16).slice(1);
    }
  }
}) 
