import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/layout/auth-layout/AuthLayout.vue'),
      children: [
        {
          path: '',
          component: () => import('@/pages/auth/login.vue'),
        }
      ]
    },
    {
      path: '/admin-board',
      component: () => import('@/layout/apps-layout/AppLayout.vue'),
      children: [
          {
            path: '',
            component: () => import('@/pages/admin-board/index.vue'),
            children: [
              {
                name:"agent-interfaces",
                path: 'agent-interfaces/:page?',
                component: () => import('@/pages/admin-board/agent-interfaces/index.vue'),
              },
              {
                name:"agent-interfaces-create",
                path: 'agent-interfaces-create',
                component: () => import('@/pages/admin-board/agent-interfaces/form.vue'),
              },
              {
                name:"agent-interfaces-edit",
                path: 'agent-interfaces-edit/:id',
                component: () => import('@/pages/admin-board/agent-interfaces/form.vue'),
              },
              {
                name:"documentation-list",
                path: 'documentation/:page?',
                component: () => import('@/pages/admin-board/documentation/index.vue'),
              },
              {
                name:"documentation-create",
                path: 'documentation-create',
                component: () => import('@/pages/admin-board/documentation/form.vue'),
              },
              {
                name:"documentation-edit",
                path: 'documentation-edit/:id',
                component: () => import('@/pages/admin-board/documentation/form.vue'),
              },
              {
                name:"admins-list",
                path: 'admins/:subproject/:page?',
                component: () => import('@/pages/admin-board/subproject-admins/index.vue'),
              },
              {
                name:"admins-create",
                path: 'admins-create/:subproject',
                component: () => import('@/pages/admin-board/subproject-admins/form.vue'),
              },
              {
                name:"admins-edit",
                path: 'admins-edit/:subproject/:id',
                component: () => import('@/pages/admin-board/subproject-admins/form.vue'),
              },
              {
                name:"subprojects-list",
                path: 'subprojects/:page?',
                component: () => import('@/pages/admin-board/subprojects/index.vue'),
              },
              {
                name:"settings-content",
                path: 'settings/content',
                component: () => import('@/pages/admin-board/settings/content.vue'),
              },
              {
                name:"settings-domains",
                path: 'settings/domains',
                component: () => import('@/pages/admin-board/settings/domains.vue'),
              },
              {
                name:"settings-layout",
                path: 'settings/layout',
                component: () => import('@/pages/admin-board/settings/layout.vue'),
              },
              {
                name:"settings-seo",
                path: 'settings/seo',
                component: () => import('@/pages/admin-board/settings/seo.vue'),
              },
              {
                name:"settings-template",
                path: 'settings/template',
                component: () => import('@/pages/admin-board/settings/template.vue'),
              },
              {
                name:"interface-settings",
                path: 'settings/interface-settings/:id',
                component: () => import('@/pages/admin-board/settings/interfaces/form.vue'),
              },
              {
                name:"interfaces-list",
                path: 'settings/interfaces-list/:page?/:id?',
                component: () => import('@/pages/admin-board/settings/interfaces/index.vue'),
              },
              {
                name:"subprojects-create",
                path: 'subprojects-create',
                component: () => import('@/pages/admin-board/subprojects/form.vue'),
              },
              {
                name:"subprojects-edit",
                path: 'subprojects-edit/:id',
                component: () => import('@/pages/admin-board/subprojects/form.vue'),
              },
              {
                name:"programs-list",
                path: 'programs/:page?',
                component: () => import('@/pages/admin-board/programs/index.vue'),
              },
              {
                name:"creators-list",
                path: 'users/creators/:page?',
                component: () => import('@/pages/admin-board/creators/index.vue'),
              },
              {
                name:"creator-requests-list",
                path: 'users/creator-requests/:page?',
                component: () => import('@/pages/admin-board/creator-requests/index.vue'),
              },
              {
                name:"creator-requests-view",
                path: 'users/creator-requests-view/:id',
                component: () => import('@/pages/admin-board/creator-requests/view.vue'),
              },
              {
                name:"users-list",
                path: 'users/users',
                component: () => import('@/pages/admin-board/users/index.vue'),
              },
              {
                name:"user-create",
                path: 'users/user-create',
                component: () => import('@/pages/admin-board/users/form.vue'),
              },
              {
                name:"user-edit",
                path: 'users/user-edit/:id',
                component: () => import('@/pages/admin-board/users/form.vue'),
              },
              {
                path: 'ai/settings',
                component: () => import('@/pages/admin-board/ai-settings/index.vue'),
              },
              {
                name:"prompts-list",
                path: 'ai/prompts',
                component: () => import('@/pages/admin-board/ai-prompts/index.vue'),
              },
              {
                name:"prompt-create",
                path: 'ai/prompt-create',
                component: () => import('@/pages/admin-board/ai-prompts/form.vue'),
              },
              {
                name:"prompt-edit",
                path: 'ai/prompt-edit/:id',
                component: () => import('@/pages/admin-board/ai-prompts/form.vue'),
              },
              {
                name:"logs-list",
                path: 'ai/logs',
                component: () => import('@/pages/admin-board/ai-logs/index.vue'),
              },
              {
                name:"log-view",
                path: 'ai/log-view/:id',
                component: () => import('@/pages/admin-board/ai-logs/view.vue'),
              },
              {
                name:"ui-statistic",
                path: 'ui/statistic',
                component: () => import('@/pages/admin-board/ui-statistic/index.vue'),
              },
              {
                name:"ui-statistic-create",
                path: 'ui/statistic-create',
                component: () => import('@/pages/admin-board/ui-statistic/form.vue'),
              },
              {
                name:"ui-statistic-edit",
                path: 'ui/statistic-edit/:id',
                component: () => import('@/pages/admin-board/ui-statistic/form.vue'),
              },
              {
                name:"ui-frontend",
                path: 'ui/frontend',
                component: () => import('@/pages/admin-board/ui-frontend/index.vue'),
              },
              {
                name:"fees-settings",
                path: 'fees/settings',
                component: () => import('@/pages/admin-board/fees-settings/index.vue'),
              },
              {
                name:"fees-personal",
                path: 'fees/personal-fees',
                component: () => import('@/pages/admin-board/personal-fees/index.vue'),
              },
              {
                name:"fees-personal-create",
                path: 'fees/personal-fees-create',
                component: () => import('@/pages/admin-board/personal-fees/form.vue'),
              },
              {
                name:"fees-personal-edit",
                path: 'fees/personal-fees-edit/:id',
                component: () => import('@/pages/admin-board/personal-fees/form.vue'),
              },
            ]
          },
        ]
    },
    {
      path: '/',
      component: () => import('@/layout/apps-layout/AppLayout.vue'),
      children: [
        {
          path: 'dashboard',
          component: () => import('@/pages/dashboard/index.vue'),
        },
        //chats
        {
          path: 'apps/chat/chats',
          component: () => import('@/pages/chat/chats/index.vue'),
        },
        {
          path: 'apps/chat/group',
          component: () => import('@/pages/chat/group/index.vue'),
        },
        {
          path: 'apps/chat/contact',
          component: () => import('@/pages/chat/contact/index.vue'),
        },
        //chat popup
        {
          path: 'apps/chat-popup/direct-message',
          component: () => import('@/pages/chat-popup/direct-message/index.vue'),
        },
        {
          path: 'apps/chat-popup/chat-bot',
          component: () => import('@/pages/chat-popup/chat-bot/index.vue'),
        },
        //calendar
        {
          path: 'apps/calendar',
          component: () => import('@/pages/calendar/index.vue'),
        },
        //email
        {
          path: 'apps/email',
          component: () => import('@/pages/email/index.vue'),
        },
        //scrumboard
        {
          path: 'apps/scrumboard/project-board',
          component: () => import('@/pages/scrumboard/projects-board/index.vue'),
        },
        {
          path: 'apps/scrumboard/kanban-board',
          component: () => import('@/pages/scrumboard/kanban-board/index.vue'),
        },
        {
          path: 'apps/scrumboard/pipeline',
          component: () => import('@/pages/scrumboard/pipeline-kanban/index.vue'),
        },

        //contacts
        {
          path: 'apps/contact/contact-list',
          component: () => import('@/pages/contact/contact-list/index.vue'),
        },
        {
          path: 'apps/contact/contact-card',
          component: () => import('@/pages/contact/contact-cards/index.vue'),
        },
        {
          path: 'apps/contact/edit-contact',
          component: () => import('@/pages/contact/edit-contact/index.vue'),
        },
        // file-manager
        {
          path: 'apps/file-manager/',
          component: () => import('@/pages/file-manager/index.vue'),
          children: [
            {
              path: 'list-view',
              component: () => import('@/pages/file-manager/list-view/index.vue'),
            },
            {
              path: 'grid-view',
              component: () => import('@/pages/file-manager/grid-view/index.vue'),
            },
          ]
        },
        //gallery
        {
          path: 'apps/gallery',
          component: () => import('@/pages/gallery/index.vue'),
        },
        //todo
        {
          path: 'apps/todo/task-list',
          component: () => import('@/pages/todo/task-list/index.vue'),
        },
        {
          path: 'apps/todo/gantt',
          component: () => import('@/pages/todo/gantt/index.vue'),
        },
        //bog
        {
          path: 'apps/blog/',
          component: () => import('@/pages/blog/index.vue'),
          children: [
            {
              path: 'posts',
              component: () => import('@/pages/blog/post/index.vue'),
            },
            {
              path: 'add-new-post',
              component: () => import('@/pages/blog/add-new-post/index.vue'),
            },
            {
              path: 'post-detail',
              component: () => import('@/pages/blog/post-details/index.vue'),
            },
          ]
        },
        //invoice
        {
          path: 'apps/invoice/',
          component: () => import('@/pages/invoice/index.vue'),
          children: [
            {
              path: 'invoice-list',
              component: () => import('@/pages/invoice/invoice-list/index.vue'),
            },
            {
              path: 'invoice-templates',
              component: () => import('@/pages/invoice/templates/index.vue'),
            },
            {
              path: 'create-invoice',
              component: () => import('@/pages/invoice/create-invoice/index.vue'),
            },
            {
              path: 'invoice-preview',
              component: () => import('@/pages/invoice/preview/index.vue'),
            },
          ]
        },
        //apps integration
        {
          path: 'apps/integrations/',
          component: () => import('@/pages/integrations/index.vue'),
          children: [
            {
              path: 'all-apps',
              component: () => import('@/pages/integrations/all-apps/index.vue'),
            },
            {
              path: '/apps/integrations/app-detail/:type?/:category?/:id?',
              name: 'app-detail',
              component: () => import('@/pages/integrations/app-detail/index.vue')
            },
            {
              path: 'integration',
              component: () => import('@/pages/integrations/integration/index.vue'),
            },
          ]
        },
        //profile
        {
          path: 'profile/',
          component: () => import('@/pages/profile/index.vue'),
        },
        {
          path: 'profile/edit-profile/',
          component: () => import('@/pages/profile/edit-profile/index.vue'),
        },
        {
          path: 'profile/account/',
          component: () => import('@/pages/profile/account/index.vue'),
        },
      ]
    },
    {
      path: '/docs',
      component: () => import('@/pages/docs/Docs.vue')
    },
    {
      path: '/docs/api',
      component: () => import('@/pages/docs/ApiDocs.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if(!authStore.isAuthenticated && to.path!=="/"){
    next("/");
  }
  if(authStore.isAuthenticated && to.path === "/"){
    next("/admin-board/documentation")
  }
  next()
});

export default router
