// Combined Utilities (Merged from domainUtils.js and the second utility file)

// --------------------------------------------------
// 1. Imports from both files, merged and deduplicated
// --------------------------------------------------
import { Country, State, City } from "country-state-city"; // (from the first file)
import { hasFlag } from "country-flag-icons";              // (common in both)
import * as Flags from "country-flag-icons/string/3x2";    // (common in both)
import * as CountryFlags from 'country-flag-icons/string/3x2';
import defaultLogo from "@/assets/img/logo-light.png";     // (from the second file)
import domainData from "./domainData";                     // (from the first file)

// --------------------------------------------------
// 2. Combined data exports
// --------------------------------------------------
// The first file exports ragData and domainData; keep them here:

// --------------------------------------------------
// 3. Functions from the first file (domainUtils.js)
//    Some have been renamed to avoid conflicts
// --------------------------------------------------

/**
 * wrapWithCircularClip (Cover Approach)
 * Ensures the entire circular region is filled by the flag,
 * with a fixed 50x50 pixel size.
 */
function wrapWithCircularClip(svgContent) {
  // 1. Extract the original viewBox or default to 640x480
  const viewBoxMatch = svgContent.match(/viewBox="([^"]+)"/);
  const originalViewBox = viewBoxMatch ? viewBoxMatch[1] : "0 0 640 480";
  const [origX, origY, origW, origH] = originalViewBox.split(/\s+/).map(Number);

  // 2. Set fixed size to 50x50
  const size = 50;
  
  // 3. Compute scaling so the smaller dimension matches 'size'
  const scale = size / Math.min(origW, origH);

  // 4. Calculate the scaled width/height
  const scaledW = origW * scale;
  const scaledH = origH * scale;

  // 5. Center the scaled content in a "50x50" viewBox
  const dx = (size - scaledW) / 2;
  const dy = (size - scaledH) / 2;

  // 6. Circle clip radius (25 for 50x50)
  const radius = size / 2;

  // 7. Build a new SVG with a circle clip path and fixed dimensions
  return `
<svg
 xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 50 50"
 width="50"
 height="50"
>
 <defs>
   <clipPath id="circleClip">
     <circle cx="25" cy="25" r="25" />
   </clipPath>
 </defs>
 <g clip-path="url(#circleClip)">
   <g transform="translate(${dx}, ${dy}) scale(${scale})">
     ${stripOuterSvgTag(svgContent)}
   </g>
 </g>
</svg>
`.trim();
}

/**
 * stripOuterSvgTag
 * Removes the outer <svg ...> and </svg> tags so we can nest
 * the inner elements under our new <g transform="...">.
 */
function stripOuterSvgTag(svgContent) {
  return svgContent
    .replace(/^<svg[^>]*>/i, "")
    .replace(/<\/svg>\s*$/i, "")
    .trim();
}

/**
 * getHealthcareDomainParts
 * Specifically handles healthcare system domains (*.medicare.dev, *.nhs.dev, etc.)
 */
function getHealthcareDomainParts(domain) {
  // Medicare systems
  if (domain.endsWith('.medicare.dev')) {
    const parts = domain.split('.');
    return {
      city: null,
      state: null,
      country: parts[0].toUpperCase() // e.g., 'us' -> 'US'
    };
  }

  // NHS systems
  if (domain.endsWith('.nhs.dev')) {
    const parts = domain.split('.');
    // Handle special UK regions
    if (domain.startsWith('gb-')) {
      const region = parts[0].toUpperCase(); // e.g., 'gb-eng' -> 'GB-ENG'
      return {
        city: null,
        state: region,
        country: 'GB'
      };
    }
    return {
      city: null,
      state: null,
      country: parts[0].toUpperCase()
    };
  }

  // Other healthcare systems
  const healthcareSystems = {
    'nhis.dev': true,
    'nhi.dev': true,
    'gkv.dev': true,
    'bpjs.dev': true,
    'uebmi.dev': true,
    'imss.dev': true,
    'sus.br.dev': true
  };

  for (const system of Object.keys(healthcareSystems)) {
    if (domain.endsWith(`.${system}`)) {
      const parts = domain.split('.');
      return {
        city: null,
        state: null,
        country: parts[0].toUpperCase()
      };
    }
  }

  return { city: null, state: null, country: null };
}

/**
 * getPhmDomainParts
 * Specifically checks domains ending with ".phm.ai" and extracts city/state/country.
 */
function getPhmDomainParts(domain) {
  // First check if it's a healthcare system domain
  if (domain.match(/\.(medicare|nhs|nhis|nhi|gkv|bpjs|uebmi|imss)\.dev$/)) {
    return getHealthcareDomainParts(domain);
  }

  // Original PHM.ai domain handling
  if (!domain.endsWith(".phm.ai")) {
    return { city: null, state: null, country: null };
  }

  const parts = domain.split(".");
  console.log(`[domainUtils] Domain parts:`, parts);

  // country.phm.ai
  if (parts.length === 3) {
    const country = parts[0];
    console.log(`[domainUtils] Country-level domain:`, { country });
    return { city: null, state: null, country: country.toUpperCase() };
  }
  // state.country.phm.ai
  else if (parts.length === 4) {
    const rawState = parts[0];
    const rawCountry = parts[1];
    const result = {
      city: null,
      state: rawState.toLowerCase(),
      country: rawCountry.toUpperCase(),
    };
    console.log(`[domainUtils] State-level domain:`, result);
    return result;
  }
  // city.state.country.phm.ai
  else if (parts.length === 5) {
    const rawCity = parts[0];
    const rawState = parts[1];
    const rawCountry = parts[2];
    const result = {
      city: rawCity.replace(/-/g, " "),
      state: rawState.toLowerCase(),
      country: rawCountry.toUpperCase(),
    };
    console.log(`[domainUtils] City/state/country domain:`, result);
    return result;
  }
  return { city: null, state: null, country: null };
}

/**
 * checkValidLocation
 * Verifies if (city, state, country) is a real place using country-state-city library.
 */
async function checkValidLocation(city, state, country) {
  if (!city || !state || !country) return false;
  const normalizedCity = city.toLowerCase().trim();
  const normalizedState = state.toLowerCase().trim();
  const normalizedCountry = country.toUpperCase().trim();

  const foundCountry = Country.getAllCountries().find(
    (c) => c.isoCode.toUpperCase() === normalizedCountry
  );
  if (!foundCountry) return false;

  const allStates = State.getStatesOfCountry(foundCountry.isoCode);
  const foundState = allStates.find(
    (s) =>
      s.name.toLowerCase() === normalizedState ||
      s.isoCode.toLowerCase() === normalizedState
  );
  if (!foundState) return false;

  const allCities = City.getCitiesOfState(foundCountry.isoCode, foundState.isoCode);
  const foundCity = allCities.find((c) => c.name.toLowerCase() === normalizedCity);

  return !!foundCity;
}

/**
 * validateDomain
 * If domain ends with ".phm.ai", parse & check location
 */
async function validateDomain(domain) {
  if (!domain.endsWith(".phm.ai")) return false;
  const { city, state, country } = getPhmDomainParts(domain);
  if (!city || !state || !country) return false;
  const isValid = await checkValidLocation(city, state, country);
  return isValid;
}

/**
 * getDomainConfig
 * Returns domain configuration (video/plain/map background, placeholders, etc.)
 * from domainData, with specialized logic for ".phm.ai" domains.
 */
function getDomainConfig(host = "") {
  try {
    if (host && host.endsWith(".phm.ai")) {
      const { city, state, country } = getPhmDomainParts(host);
      const baseConfig = domainData["phm.ai"];
      
      // Attempt to set a logo based on the country
      const logo = `/images/logos/${country?.toLowerCase()}.png`;
      console.log('[domainConfig] Setting logo:', logo);
      
      // For US states
      if (country === "US" && state) {
        const stateObj = State.getStateByCodeAndCountry(state.toUpperCase(), 'US');
        const stateName = stateObj?.name || state.toUpperCase();
        return {
          ...baseConfig,
          logo,
          title: `${stateName} Healthcare Management System - Codify HQ`,
          description: `Expert-Led, AI-Powered, Evidence-Based Healthcare Solutions for ${stateName}`,
        };
      }
      
      // For city-level domains
      if (city && state && country) {
        const formattedCity = city
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        return {
          ...baseConfig,
          logo,
          title: `${formattedCity} Healthcare Management System - Codify HQ`,
          description: `Expert-Led, AI-Powered, Evidence-Based Healthcare Solutions for ${formattedCity}`,
        };
      }

      // Country-level domain only
      return {
        ...baseConfig,
        logo,
      };
    }
    
    console.log('[domainConfig] Using default config for:', host);
    return domainData[host] || domainData.default;
  } catch (error) {
    console.warn("Error in getDomainConfig:", error);
    return domainData.default;
  }
}

/**
 * isValidDomain
 * For .phm.ai => location check is optional, otherwise check domainData
 */
function isValidDomain(domain) {
  if (domainData[domain]) {
    return true;
  }
  if (domain.endsWith(".phm.ai")) {
    return true;
  }
  return false;
}

/**
 * getLocationFromIP
 * Example IP-based location fetch
 */
async function getLocationFromIP() {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return {
      city: data.city?.toLowerCase().replace(/\s+/g, "-"),
      state: data.region_code?.toLowerCase(),
      country: data.country_code?.toUpperCase() || "US",
    };
  } catch (error) {
    console.warn("IP location detection failed:", error);
    return { city: null, state: null, country: "US" };
  }
}

/**
 * fetchCityFlag
 * Example city-based flag fetch
 */
async function fetchCityFlag(city) {
  if (!city) return null;
  try {
    const response = await fetch(`/api/flags/city/${encodeURIComponent(city)}`);
    if (response.ok) {
      const data = await response.json();
      return data.flagUrl;
    }
  } catch (error) {
    console.warn(`City flag not found for '${city}':`, error);
  }
  return null;
}

/**
 * fetchStateFlag
 * Example state-based flag fetch (currently for US)
 */
async function fetchStateFlag(state, country) {
  if (!state || !country) return null;
  if (country.toUpperCase() === "US") {
    const stateCode = state.toLowerCase();
    try {
      const svgResponse = await fetch(`/images/us/${stateCode}.svg`);
      if (svgResponse.ok) {
        return `/images/us/${stateCode}.svg`;
      }
      const pngResponse = await fetch(`/images/us/${stateCode}.png`);
      if (pngResponse.ok) {
        return `/images/us/${stateCode}.png`;
      }
    } catch (error) {
      console.warn(`[domainUtils] State flag not found for '${stateCode}':`, error);
    }
  }
  return null;
}

/**
 * resolveAdvancedLogo (Renamed from 'resolveLogo' in the first file)
 * Returns a circular clipped SVG flag or other fallback based on domain
 */
async function resolveAdvancedLogo(domain) {
  try {
    console.log(`[domainUtils] Resolving advanced logo for domain: ${domain}`);
    // This logic is specialized for ".phm.ai" domains, city/state/country lookups, etc.
    if (domain.endsWith(".phm.ai")) {
      const { city, state, country } = getPhmDomainParts(domain);
      console.log(`[domainUtils] Parsed parts:`, { city, state, country });

      // State-level (e.g. ca.us.phm.ai)
      if (state && country === "US") {
        const stateFlag = `/images/us/${state.toLowerCase()}.png`;
        try {
          const response = await fetch(stateFlag);
          if (response.ok) {
            return stateFlag;
          }
        } catch (e) {
          console.warn(`[domainUtils] Error checking state flag:`, e);
        }
      }

      // City-level
      if (city) {
        const cityFlag = await fetchCityFlag(city);
        if (cityFlag) {
          return cityFlag;
        }
        // Fallback to state flag if city isn't found
        if (state && country === "US") {
          const stateFlag = `/images/us/${state.toLowerCase()}.png`;
          return stateFlag;
        }
      }

      // Fallback to country flag (circular clipped)
      if (country && hasFlag(country)) {
        const baseSvg = Flags[country];
        return `data:image/svg+xml;base64,${btoa(wrapWithCircularClip(baseSvg))}`;
      }

      // Default US circular clipped flag
      const baseSvg = Flags["US"];
      return `data:image/svg+xml;base64,${btoa(wrapWithCircularClip(baseSvg))}`;
    }

    // Otherwise, fallback to a default or a known path
    return '/images/logo/default-loader-logo.png';

  } catch (error) {
    console.error("[domainUtils] Error in resolveAdvancedLogo:", error);
    return '/images/logo/default-loader-logo.png';
  }
}

/**
 * getPlaceholdersByDomain
 * Returns an array of placeholders for user input, possibly with translations
 */
function getPlaceholdersByDomain(domain = '') {
  console.log('[domainUtils] Getting placeholders for domain:', domain);
  try {
    // Use domain config
    const config = getDomainConfig(domain);
    
    // For .phm.ai, produce default English medical placeholders, then optionally translate
    if (domain.endsWith('.phm.ai')) {
      let placeholders = [
        "I have a cold",
        "I have the flu",
        "My stomach hurts",
        "I have COVID",
        "I feel dizzy",
        "I have a fever",
        "I have a sore throat",
        "I am vomiting",
        "I have diarrhea",
        "I have an allergy"
      ];

      // Attempt to do a "country->language" mapping for translations
      const { country } = getPhmDomainParts(domain);
      if (country) {
        const countryName = Country.getCountryByCode(country)?.name || '';
        const lang = mapCountryToLanguage(countryName);
        placeholders = placeholders.map(ph => translatePlaceholder(ph, lang));
      }

      return placeholders;
    }
    
    // Otherwise, for non-phm.ai domains, fall back to config placeholders or default
    if (config && config.placeholders && config.placeholders.length > 0) {
      console.log('[domainUtils] Using domain-specific placeholders:', config.placeholders);
      return config.placeholders;
    }
    console.log('[domainUtils] Using default placeholders');
    return domainData.default.placeholders;
    
  } catch (error) {
    console.error('[domainUtils] Error getting placeholders:', error);
    return domainData.default.placeholders;
  }
}

// A large map of "country -> primary 2-letter language code"
const COUNTRY_LANGUAGE_MAP = {
  // ... (Full dictionary from the first file) ...
  "afghanistan": "fa",
  // ...
  "zimbabwe": "en",
};

/**
 * mapCountryToLanguage
 * Returns a 2-letter language code for the given country name
 * or 'en' by default if not found.
 */
function mapCountryToLanguage(country) {
  if (!country) return 'en';
  const normalized = country.toLowerCase()
    .trim()
    .replace(/^the\s+/, '')
    .replace(/\s+/g, ' ')
    .replace(/\([^)]+\)/g, '')
    .trim();

  const specialCases = {
    'usa': 'united states',
    'uk': 'united kingdom',
    'uae': 'united arab emirates',
    'drc': 'congo, democratic republic of the',
    'car': 'central african republic',
    'prc': 'china',
    'roc': 'taiwan',
    'dprk': 'north korea',
    'rok': 'south korea'
  };

  const lookupName = specialCases[normalized] || normalized;
  return COUNTRY_LANGUAGE_MAP[lookupName] || 'en';
}

/**
 * translatePlaceholder
 * A small dictionary-based translation for placeholders.
 * Falls back to the English text if the target language isn't found.
 */
function translatePlaceholder(placeholder, lang) {
  const dictionary = {
    "I have a cold": {
      en: "I have a cold",
      // ...
      yi: "איך האָב אַ פֿאַרקילונג",
    },
    "I have the flu": {
      en: "I have the flu",
      // ...
      yi: "איך האב די פלו"
    },
    "My stomach hurts": {
      en: "My stomach hurts",
      // ...
      yi: "איך האב פיבער"
    },
    "I have COVID": {
      en: "I have COVID",
      // ...
      hu: "COVID-os vagyok"
    },
    "I feel dizzy": {
      en: "I feel dizzy",
      // ...
      hu: "Szédülök"
    },
    "I have a fever": {
      en: "I have a fever",
      // ...
      yi: "איך האב פיבער"
    },
    "I have a sore throat": {
      en: "I have a sore throat",
      // ...
      yi: "מיין האַלדז טוט וויי"
    },
    "I am vomiting": {
      en: "I am vomiting",
      // ...
      yi: "איך ברעך"
    },
    "I have diarrhea": {
      en: "I have diarrhea",
      // ...
      yi: "איך האב די פלו"
    },
    "I have an allergy": {
      en: "I have an allergy",
      // ...
      yi: "איך האב אַן אַלערגיע"
    }
  };

  const translations = dictionary[placeholder];
  if (!translations) {
    return placeholder;
  }
  return translations[lang] || placeholder;
}

// --------------------------------------------------
// 4. Functions from the second file, merged or renamed
// --------------------------------------------------

/**
 * getDomainParts
 * (This is the version from the second file. We'll keep it 
 *  as a simple, generic domain-splitting function.)
 */
export function getDomainParts(domain) {
  try {
    console.log(`Extracting domain parts from: ${domain}`);
    const parts = domain.split('.');
    const cityPart = parts[0];
    const statePart = parts[1];
    const countryPart = parts[2];

    console.log(`Extracted parts - City: ${cityPart}, State: ${statePart}, Country: ${countryPart ? countryPart.toUpperCase() : 'N/A'}`);

    return {
      city: cityPart,
      state: statePart,
      country: countryPart ? countryPart.toUpperCase() : null,
    };
  } catch (error) {
    console.error('Error extracting domain parts:', error);
    return { city: null, state: null, country: null };
  }
}

/**
 * resolveBasicLogo
 * (Kept from the second file's 'resolveLogo' to handle a simpler approach:
 *  tries to load /[domain].png, then falls back to a country flag or defaultLogo)
 */
export function resolveBasicLogo(domain) {
  try {
    console.log(`Resolving logo for domain: ${domain}`);
    
    // Handle localhost case
    if (domain === 'localhost') {
      return defaultLogo;
    }
    
    // Try to load domain-specific logo first
    try {
      const logoUrl = `/${domain}.png`;
      console.log(`Using domain-specific logo for: ${domain}`);
      return logoUrl;
    } catch (logoError) {
      console.log(`No domain-specific logo found for: ${domain}, falling back to country flag`);
      
      // Fallback to country flag logic
      const { country } = getDomainParts(domain);
      if (country && hasFlag(country)) {
        const svgFlag = Flags[country];
        if (svgFlag) {
          console.log(`Using flag for country: ${country}`);
          return `data:image/svg+xml;base64,${btoa(svgFlag)}`;
        } else {
          console.warn(`No flag found for country: ${country}`);
        }
      } else {
        console.warn(`No valid country code found for domain: ${domain}`);
      }
    }

    // Final fallback to default logo
    return defaultLogo;
  } catch (error) {
    console.error('Error resolving logo:', error);
    return defaultLogo;
  }
}

/**
 * getCurrentDomain
 * (From the second file, original logic to detect the domain from window.hostname)
 */
export const getCurrentDomain = () => {
  if (typeof window === 'undefined') return 'phm.ai';
  const hostname = window.location.hostname;

  // Healthcare system domains
  const healthcareDomains = {
    // Medicare systems
    'us.medicare.dev': true,
    'au.medicare.dev': true,
    'ca.medicare.dev': true,

    // NHS systems
    'uk.nhs.dev': true,
    'gb-eng.nhs.dev': true,
    'gb-sct.nhs.dev': true,
    'gb-wls.nhs.dev': true,
    'gb-nir.nhs.dev': true,
    'za.nhs.dev': true,

    // NHIS/NHI systems
    'kr.nhis.dev': true,
    'jp-nhi.dev': true,
    'tw-nhi.dev': true,
    'gh.nhis.dev': true,
    'ng.nhis.dev': true,

    // European systems
    'de.gkv.dev': true,
    'ch.foph.dev': true,
    'pl.nfz.dev': true,
    'at.bmi.dev': true,
    'fr.securite-sociale.dev': true,

    // Asian systems
    'in.cghs.dev': true,
    'id.bpjs.dev': true,
    'cn.cn-bmi.dev': true,
    'cn.uebmi.dev': true,

    // Americas
    'mx.imss.dev': true,
    'br.sus-br.dev': true,

    // Other systems
    'cn.hiurr.dev': true,
  };

  // US State Medicaid domains
  const medicaidStates = [
    'ak', 'al', 'ar', 'as', 'az', 'ca', 'co', 'ct', 'dc', 'de',
    'fl', 'ga', 'gu', 'hi', 'ia', 'id', 'il', 'in', 'ks', 'ky',
    'la', 'ma', 'md', 'me', 'mh', 'mi', 'mn', 'mo', 'mp', 'ms',
    'mt', 'nc', 'nd', 'ne', 'nh', 'nj', 'nm', 'nv', 'ny', 'oh',
    'ok', 'or', 'pa', 'pr', 'pw', 'ri', 'sc', 'sd', 'tn', 'tx',
    'ut', 'va', 'vi', 'vt', 'wa', 'wi', 'wv', 'wy'
  ];

  // Check for exact domain match first
  if (healthcareDomains[hostname]) {
    return hostname;
  }

  // Check for Medicaid state domains
  for (const state of medicaidStates) {
    if (hostname === `${state}.medicaid.dev`) {
      return hostname;
    }
  }

  // Check for California prison subdomains
  if (hostname.match(/^[a-z]+\.ca\.us\.phm\.ai$/)) {
    return 'ca.us.phm.ai';
  }

  // Default to global domain
  return 'phm.ai';
};

// Map of country codes to GeoBuff region folder names
const countryToRegionMap = {
  'AU': 'australia',
  'AR': 'argentina',
  'BR': 'brazil',
  'CA': 'canada',
  'CO': 'colombia',
  'FR': 'france',
  'DE': 'germany',  // German regions
  'IT': 'italy',
  'JP': 'japan',
  'RU': 'russia',
  'KR': 'south-korea',
  'ES': 'spain',
  'UA': 'ukraine',
  'US': 'united-states',
  'CH': 'switzerland',  // Swiss regions
  'PL': 'poland',      // Polish regions
  'AT': 'austria',     // Austrian regions
};

/**
 * getRegionalFlag
 * Returns the correct flag URL for a region, with special handling for US states
 */
const getRegionalFlag = (countryCode, regionCode) => {
  // Special handling for US states
  if (countryCode === 'US') {
    return `/images/us/${regionCode.toLowerCase()}.png`;
  }

  if (countryCode === 'CA') {
    return `/images/ca/${regionCode.toLowerCase()}.png`;
  }

  if (countryCode === 'AU') {
    return `/images/au/${regionCode.toLowerCase()}.svg`;
  }

  // For other countries, use GeoBuff regions
  const regionFolder = countryToRegionMap[countryCode.toUpperCase()];
  if (regionFolder) {
    return `https://raw.githubusercontent.com/GeoBuff/flags/main/regions/${regionFolder}/${regionCode.toLowerCase()}.svg`;
  }

  return null;
};

/**
 * Converts "regionName" (e.g. "Tennessee") into
 * the official two-letter ISO code (e.g. "TN").
 * If no match is found, returns the original string.
 */
function toIsoCode(countryCode, regionName = "") {
  if (!regionName) return "";
  // State.getStatesOfCountry expects e.g. "US", "CA", "AU"
  const allStates = State.getStatesOfCountry(countryCode);
  if (!allStates || !allStates.length) return regionName;

  // Compare case-insensitively
  const match = allStates.find(
    (s) => s.name.toLowerCase() === regionName.toLowerCase() || s.isoCode.toLowerCase() === regionName.toLowerCase()
  );
  // Return the isoCode (e.g. "TN") if found, else leave it as given
  return match ? match.isoCode : regionName;
}

/**
 * getFlagUrl 
 * Returns an image path for a region or country, using ISO-coded files
 * For example "US-Tennessee" => "/images/medicare/us/tn.png"
 */
export function getFlagUrl(code, currentDomain = getCurrentDomain()) {
  if (!code) {
    return "/images/flags/default.svg";
  }

  const [countryCode, regionName] = code.includes("-")
    ? code.split("-")
    : [code, null];

  // e.g. "us" => "US"
  const upperCountry = countryCode.toUpperCase();

  // If the domain includes "medicare.dev"
  if (currentDomain.includes("medicare.dev")) {
    // If we have a region name like "Tennessee"
    if (regionName) {
      // Convert region name to ISO e.g. 'Tennessee' => 'TN' (if it exists)
      const isoRegion = toIsoCode(upperCountry, regionName).toLowerCase();

      switch (upperCountry) {
        case "US":
          return `/images/medicare/us/${isoRegion}.png`;
        case "CA":
          return `/images/medicare/ca/${isoRegion}.png`;
        case "AU":
          return `/images/medicare/au/${isoRegion}.png`;
        default:
          console.warn(`[domainUtils] No known Medicare folder for country: ${upperCountry}`);
          return "/images/flags/default.svg";
      }
    }

    // If we only have a country code (e.g. "US"), do a simpler path
    switch (upperCountry) {
      case "US":
        return "/images/medicare/us/us.png";
      case "CA":
        return "/images/medicare/ca/ca.png";
      case "AU":
        return "/images/medicare/au/au.png";
      default:
        return "/images/flags/default.svg";
    }
  }

  // ... similarly handle "medicaid.dev" or "nhs.dev" if needed, also using toIsoCode

  // If no special domain and no regionName, might try "country-flag-icons"
  if (!regionName && hasFlag(upperCountry)) {
    return `data:image/svg+xml;base64,${btoa(CountryFlags[upperCountry])}`;
  }

  // If we do have a region name, use a generic fallback path
  if (regionName) {
    const isoRegion = toIsoCode(upperCountry, regionName).toLowerCase();
    return `/images/${upperCountry.toLowerCase()}/${isoRegion}.png`;
  }

  // Lastly, fallback if none matched
  return "/images/flags/default.svg";
}

/**
 * Returns the appropriate URL for a region based on the code format
 */
export function getRegionUrl(codeOrName = '') {
  const currentDomain = getCurrentDomain();
  const trimmedName = codeOrName.trim();

  // If it's a hyphenated code (e.g., "US-Virginia", "CA-Ontario", "AU-Victoria")
  if (trimmedName.includes('-')) {
    const [country, regionName] = trimmedName.split('-');
    const upperCountry = country.toUpperCase();
    
    // Get all states for this country
    const states = State.getStatesOfCountry(upperCountry);
    
    // Try to find state by name or code
    const stateData = states.find(state => 
      state.name.toLowerCase() === regionName.toLowerCase() || 
      state.isoCode.toLowerCase() === regionName.toLowerCase()
    );

    if (!stateData) {
      console.warn(`No state data found for ${regionName} in ${upperCountry}`);
      return `https://${regionName.toLowerCase().replace(/\s+/g, '-')}.${country.toLowerCase()}.phm.ai`;
    }

    const stateCode = stateData.isoCode.toLowerCase();

    // Handle all healthcare domains
    if (currentDomain.includes('medicare.dev')) {
      switch (upperCountry) {
        case 'US': return `https://${stateCode}.us.phm.ai`;
        case 'CA': return `https://${stateCode}.ca.phm.ai`;
        case 'AU': return `https://${stateCode}.au.phm.ai`;
      }
    }
    
    if (currentDomain.includes('nhs.dev')) {
      switch (upperCountry) {
        case 'GB':
          if (currentDomain.startsWith('gb-')) {
            const region = currentDomain.split('.')[0]; // e.g., 'gb-eng'
            return `https://${stateCode}.${region}.phm.ai`;
          }
          return `https://${stateCode}.gb.phm.ai`;
        case 'ZA': return `https://${stateCode}.za.phm.ai`;
      }
    }

    // NHIS/NHI systems
    if (currentDomain.includes('nhis.dev')) {
      switch (upperCountry) {
        case 'KR': return `https://${stateCode}.kr.phm.ai`;
        case 'GH': return `https://${stateCode}.gh.phm.ai`;
        case 'NG': return `https://${stateCode}.ng.phm.ai`;
      }
    }

    if (currentDomain.includes('nhi.dev')) {
      switch (upperCountry) {
        case 'JP': return `https://${stateCode}.jp.phm.ai`;
        case 'TW': return `https://${stateCode}.tw.phm.ai`;
      }
    }

    // European systems
    const europeanSystems = {
      'gkv.dev': { country: 'DE' },
      'foph.dev': { country: 'CH' },
      'nfz.dev': { country: 'PL' },
      'bmi.dev': { country: 'AT' },
      'securite-sociale.dev': { country: 'FR' }
    };

    for (const [domain, config] of Object.entries(europeanSystems)) {
      if (currentDomain.includes(domain) && upperCountry === config.country) {
        return `https://${stateCode}.${config.country.toLowerCase()}.phm.ai`;
      }
    }

    // Asian systems
    const asianSystems = {
      'cghs.dev': { country: 'IN' },
      'bpjs.dev': { country: 'ID' },
      'cn-bmi.dev': { country: 'CN' },
      'uebmi.dev': { country: 'CN' }
    };

    for (const [domain, config] of Object.entries(asianSystems)) {
      if (currentDomain.includes(domain) && upperCountry === config.country) {
        return `https://${stateCode}.${config.country.toLowerCase()}.phm.ai`;
      }
    }

    // Americas
    if (currentDomain.includes('imss.dev') && upperCountry === 'MX') {
      return `https://${stateCode}.mx.phm.ai`;
    }
    if (currentDomain.includes('sus-br.dev') && upperCountry === 'BR') {
      return `https://${stateCode}.br.phm.ai`;
    }

    // Default case for any other country
    return `https://${stateCode}.${country.toLowerCase()}.phm.ai`;
  }

  // Handle country-level URLs
  const countryData = Country.getCountryByCode(trimmedName.toUpperCase())
                   || Country.getAllCountries().find(
                        (c) => c.name.toLowerCase() === trimmedName.toLowerCase()
                      );

  if (!countryData) {
    console.warn(`No country data found for: ${trimmedName}`);
    return `https://${trimmedName.toLowerCase().replace(/\s+/g, '-')}.phm.ai`;
  }

  // Special case: Healthcare domains
  if (currentDomain.includes('medicare.dev')) {
    switch (countryData.isoCode) {
      case 'US': return 'https://us.medicare.dev';
      case 'CA': return 'https://ca.medicare.dev';
      case 'AU': return 'https://au.medicare.dev';
    }
  }

  // Map other healthcare domains to their respective URLs
  const healthcareDomains = {
    'nhs.dev': ['GB', 'ZA'],
    'nhis.dev': ['KR', 'GH', 'NG'],
    'nhi.dev': ['JP', 'TW'],
    'gkv.dev': ['DE'],
    'foph.dev': ['CH'],
    'nfz.dev': ['PL'],
    'bmi.dev': ['AT'],
    'securite-sociale.dev': ['FR'],
    'cghs.dev': ['IN'],
    'bpjs.dev': ['ID'],
    'cn-bmi.dev': ['CN'],
    'uebmi.dev': ['CN'],
    'imss.dev': ['MX'],
    'sus-br.dev': ['BR']
  };

  for (const [domain, countries] of Object.entries(healthcareDomains)) {
    if (currentDomain.includes(domain) && countries.includes(countryData.isoCode)) {
      return `https://${countryData.isoCode.toLowerCase()}.${domain}`;
    }
  }

  return `https://${countryData.isoCode.toLowerCase()}.phm.ai`;
}

// --------------------------------------------------
// 5. Final combined exports
// --------------------------------------------------

// We can export a unified `domainUtils` object that includes everything.
export const domainUtils = {
  // Re-exported data:
  domainData,

  // The standard "getCurrentDomain" approach from second file
  getCurrentDomain,
  
  // Shared or renamed functions:
  getDomainParts,           // from second file (generic)
  getPhmDomainParts,        // specialized for phm.ai
  checkValidLocation,
  validateDomain,
  getDomainConfig,
  isValidDomain,
  getLocationFromIP,
  fetchCityFlag,
  fetchStateFlag,
  resolveBasicLogo,         // from second file, simpler approac
  resolveAdvancedLogo,      // from first file, advanced approach
  wrapWithCircularClip,
  getPlaceholdersByDomain,
  mapCountryToLanguage,
  translatePlaceholder,

  // Additional
  getFlagUrl,
  getRegionUrl,
  
  // Also store references to external packages if needed
  Country,
  State,
  City,
  hasFlag,
  Flags
};

// Default export for backward compatibility
export default domainUtils;