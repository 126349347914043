export default {
    install(app) {
        app.config.globalProperties.$imgSrc = (name, size) => {
            const baseURL = process.env.NODE_ENV === "development"
                ? `http://hms-core.local/`
                : `https://web.codifyhealthcare.com/`;
           return `${baseURL}public/files/${size}/${name}`;
        };

        app.config.globalProperties.$fileLink = (path) => {
            return path ? `https://web.codifyhealthcare.com/public/download/${path.replace(/\//g, "-")}` : null;
        };

        app.config.globalProperties.$getId = () => {
            return Math.random().toString(36).substring(2, 12);
        };

        app.config.globalProperties.$base64 = (file)=> {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
            });
        }
    }
};


