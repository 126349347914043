import '@/styles/scss/style.scss'
import piniaPersistedState from 'pinia-plugin-persistedstate';
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useAuthStore } from '@/stores/auth';
import { useValidationStore } from '@/stores/validation';
import helper from "./plugins/helper.js";
import { initializeApi } from './plugins/api';
import App from './App.vue'
import router from './router'
//components
import FeatherIcon from '@/hk-components/Icons/FeatherIcon.vue'
import simplebar from 'simplebar-vue'
import VueApexCharts from "vue3-apexcharts";
import VueSweetalert2 from 'vue-sweetalert2';
import i18n from "./i18n.js";
//custom components
import HkDateRangePicker from "@/hk-components/@hk-daterangepicker/HkDateRangePicker.vue";
import HkRating from './hk-components/@hk-rating/HkRating.vue';
import { tooltipDirective } from '@/util/tooltip'



const app = createApp(App)
app.use(i18n)
app.use(helper)
const pinia = createPinia();
pinia.use(piniaPersistedState);
app.use(pinia)
const authStore = useAuthStore();
const validationStore = useValidationStore();
import apiPlugin from "./plugins/api.js";
initializeApi(authStore, validationStore);
app.config.globalProperties.$api = apiPlugin().methods;

const removeTooltips = () => {
    const tooltips = document.querySelectorAll('.tooltip');
    tooltips.forEach(tooltip => tooltip.remove());
};

// Следим за изменением маршрута и убираем tooltips
router.afterEach(() => {
    removeTooltips();
});

app.use(router)
app.use(VueSweetalert2);


//vue components
app.component('simplebar', simplebar);
app.component("apexchart", VueApexCharts);
app.component('feather-icon', FeatherIcon);
app.component('hk-rating', HkRating); 
app.component('DateRangePicker', HkDateRangePicker);
//vue directives
app.directive('tooltip', tooltipDirective)

import { useSubprojectStore } from '@/stores/subproject';
(async () => {
    try {
        const subprojectStore = useSubprojectStore();
        const response = await app.config.globalProperties.$api.get('board');
        subprojectStore.setDomainInfo(response);
    } catch (error) {
        console.error("Failed to fetch domain info:", error);
    } finally {
        app.mount('#app');
    }
})();

